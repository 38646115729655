// Run the following when the page has finished loading.
import * as siteConfig from "./_variable";

// Remove the loading spinner.
window.removeGalleryLoadingSpinner = function() {
  let galleryLoadingSpinner = document.getElementById('gallery-loading-spinner');
  if (galleryLoadingSpinner !== null) {
    galleryLoadingSpinner.remove();
  }
};

// Get the HTML for the gallery item.
window.insertGalleryItem = function(item) {
  // Add the gallery item into the dom.
  document.getElementById('gallery-items-container').insertAdjacentHTML('beforeend', ' \
          <div class="row gallery-item"> \
            ' + imageHtml + ' \
          </div>');
};

// Run the build up of the page once the page is finished loading.
ready(() => {
  let galleryContainer = document.querySelector('#gallery-container');
  if (galleryContainer !== null) {
    // Retrieve all the gallery items from the API.
    fetchApiData(siteConfig.api.domain + 'news/' + siteConfig.api.company + '?referrer=' + siteConfig.api.referrer).then(news => {
      let imageHtml = '';
      news.items.forEach(item => {
          item.images.forEach(image => {
            imageHtml += '\
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 ps-0 pb-1">\
              <a class="gallery-item" data-fslightbox="' + item.slug + '" href="' + siteConfig.api.domain + 'news/' + siteConfig.api.company + '/' + item.id + '/image/' + image.image + '?referrer=' + siteConfig.api.referrer + '"> \
          <img class="gallery-image" src="' + siteConfig.api.domain + 'news/' + siteConfig.api.company + '/' + item.id + '/thumb/' + image.thumb + '?referrer=' + siteConfig.api.referrer + '" /> \
          </a>\
            </div>';
          });
      });

      removeGalleryLoadingSpinner();

      galleryContainer.innerHTML = imageHtml;

      // Initialise the lightbox with our news images.
      refreshFsLightbox();

    }).catch((error) => {
      console.log(error);
      galleryContainer.innerHTML = '<div class="col-12 text-danger">Something went wrong, we couldn\'t load the latest news. Please try again in a few minutes. hi</div>';
      removeGalleryLoadingSpinner();
    });
  }
}
);